/**
 * @prettier
 */

import capitalize from 'lodash/capitalize'
import trim from 'lodash/trim'
import snakeCase from 'lodash/snakeCase'
import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isNull from 'lodash/isNull'
import omit from 'lodash/omit'
import map from 'lodash/map'
import last from 'lodash/last'
import round from 'lodash/round'
import Cookies from 'js-cookie'
import strftime from 'strftime'
import { animateScroll as scroll } from 'react-scroll'
import qs from 'query-string'
import pluralize from 'pluralize'

import { UserRole } from 'types/user'
import { ICampaign } from 'types/campaign'
import { IDynamicObj, AllowedSpaces } from 'types/common'
import { IAccessibleSitesCampaign } from 'stores/models/current_user'

import {
  CAMPAIGN_STATUSES_ORDER,
  HUMANIZED_ROLE_NAMES,
  PLATFORM_EXTENSION_ICON_NAMES,
  PLATFORM_EXTENSION_NAMES,
  PLACEMENTS_ABBREVIATIONS,
  TIME,
} from './constants'

export const getPrevUrl = (url: string): string | void => {
  const parsedUrl = qs.parse(url)
  let result

  Object.keys(parsedUrl).forEach(key => {
    if (key.includes('prev_url')) {
      result = parsedUrl[key]
    }
  })

  return result
}

interface StrftimeFormatProps {
  date?: Date
  string: string
}

export const strftimeFormat = (data: StrftimeFormatProps): string => {
  /*
    date can be:
    dayjs().toDate()
    new Date(1362088800000)
  */

  const { string, date } = data

  if (date) {
    return strftime(string, date)
  } else {
    return strftime(string)
  }
}

interface ScrollToProps {
  duration?: number
  position?: number
  smooth?: boolean
}

export const scrollTo = ({
  position = 0,
  duration = 500,
  smooth = true,
}: ScrollToProps = {}): void => {
  scroll.scrollTo(position, {
    duration,
    smooth,
  })
}

export const removeEmptyStrings = (hash: IDynamicObj): IDynamicObj => {
  return transform(hash, (a: IDynamicObj, value: any, k: string) => {
    if (value !== '') a[k] = value

    return a
  })
}

export const wrapInto = (namespace = '', data: IDynamicObj = {}): IDynamicObj => ({
  [namespace]: data,
})

export const humanizeString = (str: string): string => {
  return capitalize(trim(snakeCase(str).replace(/_id$/, '').replaceAll('_', ' ')))
}

export const showRestrictedText = (condition: boolean, role: UserRole): string | void => {
  if (condition) {
    return `Users with ${HUMANIZED_ROLE_NAMES[role]} role are not allowed to perform this action.`
  }
}

export const deepDiff = (object: IDynamicObj, base: IDynamicObj): IDynamicObj => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changes(object: any, base: any) {
    return transform(object, function (result: IDynamicObj, value: any, key: string) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }

  return changes(object, base)
}

export const addClassName = (props: IDynamicObj = {}, injectedClassName = ''): any => ({
  ...props,
  className: `${props.className || ''} ${injectedClassName}`,
})

export const openNewTab = (e: any = {}): boolean | void => {
  if (e.metaKey || e.ctrlKey) {
    window.open(e.target.href)

    return true
  }
}

export const getURLParametersValue = (param: string): boolean => {
  return window.location.href.includes(param)
}

export const sortCampaignsByStatusById = (campaigns: ICampaign[] = []): ICampaign[] => {
  return campaigns.sort((a, b) => {
    const statusAIndex = CAMPAIGN_STATUSES_ORDER.indexOf(a.status.toUpperCase())
    const statusBIndex = CAMPAIGN_STATUSES_ORDER.indexOf(b.status.toUpperCase())

    return statusAIndex - statusBIndex || b.id - a.id
  })
}

interface IsForceIntegrationProps {
  currentUrl: string
  forceIntegration: boolean
}

export const isForceIntegration = ({
  forceIntegration,
  currentUrl,
}: IsForceIntegrationProps): boolean => {
  return (
    getSiteSpace(currentUrl) === 'referral' &&
    forceIntegration &&
    !Cookies.get('skip_integration')
  )
}

interface EntryDateProps {
  endDate: string
  liveFrom: string
  liveTo: string
  startDate: string
}

export const entryDate = ({
  startDate,
  endDate,
  liveFrom,
  liveTo,
}: EntryDateProps): boolean => {
  const dayjsStartDate = dayjs(startDate).startOf('day')
  const dayjsEndDate = dayjs(endDate).endOf('day')
  const dayjsLiveFrom = dayjs(liveFrom).startOf('day')
  const dayjsLiveTo = dayjs(liveTo).endOf('day')
  let result = false

  if (liveFrom && liveTo) {
    result =
      dayjsLiveFrom.isBetween(dayjsStartDate, dayjsEndDate, null, '[]') ||
      dayjsLiveTo.isBetween(dayjsStartDate, dayjsEndDate, null, '[]') ||
      (dayjsLiveFrom.isBefore(dayjsStartDate, dayjsEndDate) &&
        !dayjsLiveTo.isBefore(dayjsStartDate, dayjsEndDate))
  } else if (liveFrom) {
    result = dayjsLiveFrom.isBetween(dayjsStartDate, dayjsEndDate)
  }

  return result
}

export const yearsFromDate = ({ date }: { date: string }): string[] => {
  const years = []
  let dateStart = dayjs(date).startOf('year')
  const dateEnd = dayjs().endOf('year')

  while (dateEnd.diff(dateStart, 'years') >= 1) {
    years.unshift(dateStart.format('YYYY'))
    dateStart = dateStart.add(1, 'year')
  }

  return years
}

interface YearsFromDateForTilesResult {
  label: string
  value: {
    endDate: string
    startDate: string
  }
}

export const yearsFromDateForTiles = ({
  date,
}: {
  date: string
}): YearsFromDateForTilesResult[] => {
  const years = yearsFromDate({ date })

  return years.map((year, i) => {
    const yearIndex = i + 1

    return {
      label:
        i === 0 ? 'Previous year' : `${yearIndex} ${pluralize('year', yearIndex)} ago`,
      value: {
        startDate: `${year}-01-01`,
        endDate: `${year}-12-31`,
      },
    }
  })
}

interface SubtractMonthsFromDateProps {
  date: string
  format?: string
  range?: number
}

export const subtractMonthsFromDate = ({
  date,
  range = 12,
  format = 'YYYY-MM-DD',
}: SubtractMonthsFromDateProps): string => {
  return dayjs(date).subtract(range, 'month').format(format)
}

interface SubtractMonthsFromDateForTilesProps {
  endDate: string
}

interface SubtractMonthsFromDateForTilesResult {
  endDate: string
  startDate: string
}

export const subtractMonthsFromDateForTiles = ({
  endDate,
}: SubtractMonthsFromDateForTilesProps): SubtractMonthsFromDateForTilesResult => {
  return {
    startDate: subtractMonthsFromDate({ date: endDate }),
    endDate,
  }
}

interface IntegrationPathProps {
  platform: string
  siteCachedSlug: string
}

export const integrationPath = ({
  siteCachedSlug,
  platform,
}: IntegrationPathProps): string => {
  let path = ''

  switch (platform) {
    case 'shopify':
      path = Routes.integration_shopify_site_path(siteCachedSlug)
      break
    case 'magento':
      path = Routes.integration_magento_site_path(siteCachedSlug)
      break
    case 'demandware':
      path = Routes.integration_demandware_site_path(siteCachedSlug)
      break
    default:
      path = Routes.integration_other_site_path(siteCachedSlug, 'general_integration')
  }

  return path
}

export const checkRole = (
  isStaffMember: boolean,
  accessibleSite: IAccessibleSitesCampaign,
  role: UserRole,
): boolean => {
  // It is important that these be in ascending order
  // from the less permissive role to the most powerful.
  const roles = ['csp_only', 'read', 'write', 'admin']

  if (!roles.includes(role)) {
    throw 'Invalid role'
  }

  return isStaffMember || roles.indexOf(accessibleSite?.role) >= roles.indexOf(role)
}

export const nullToString = (value: null | any): string | any => {
  return isNull(value) ? 'null' : value
}

export const documentationURL = (path = ''): string => {
  return `https://${documentationURLHost()}/${path}`
}

const documentationURLHost = (): string => {
  const host = document.querySelector('.js-constants')?.dataset.documentationHost

  return window.Constants?.DOCUMENTATION_HOST || host
}

interface DateTimeWithTooltipProps {
  timeZoneIdentifier: string
  timeZoneName: string
  value?: string
}

export const DateTimeWithTooltip = ({
  value,
  timeZoneName,
  timeZoneIdentifier,
}: DateTimeWithTooltipProps): JSX.Element | null => {
  if (!value) return null
  const date = dayjs(value).tz(timeZoneIdentifier)

  return (
    <React.Fragment>
      <span
        className="datetime ac-time-zone-name is-without-pointer"
        title={timeZoneName}
        data-toggle="tooltip"
        data-container="body"
      >
        <span className="mrs ac-date-time">{date.format('MM/DD/YYYY [at] hh:mm A')}</span>
      </span>
      <small className="datetime-label">({date.fromNow()})</small>
    </React.Fragment>
  )
}

export const convertValidationErrorToHtml = (data: IDynamicObj): JSX.Element[] => {
  return map(data, (value, key) => (
    <div key={key}>
      <strong>{key}</strong>
      {map(value, (error, index) => (
        <p key={index}>{error}</p>
      ))}
    </div>
  ))
}

interface UpdateQueryParamsProps {
  key: string
  location?: any
  value: string
}

export const updateQueryParams = ({
  location = {},
  key,
  value,
}: UpdateQueryParamsProps): string => {
  const search = qs.parse(location.search || {})
  const params = value ? { ...search, [key]: value } : omit(search, key)
  const queryParams = qs.stringify(params)

  return `${location.pathname}${queryParams ? '?' : ''}${queryParams}`
}

export const getMenuIntegrationName = (platform: string): string => {
  const platformName = PLATFORM_EXTENSION_NAMES[platform]

  return platformName ? `${platformName} Integration` : 'Integration'
}

export const getMenuIntegrationIcon = (platform: string): string => {
  const iconName = PLATFORM_EXTENSION_ICON_NAMES[platform]

  return iconName ? iconName : 'integration'
}

export const delayForRetry = (count = 0): number => {
  switch (count) {
    case 0:
      return 0
    case 1:
    case 2:
    case 3:
      return 1000
    default:
      return 3000
  }
}

export const goToTwoFactorModal = (): void => {
  window.location.href = `${defaultUrlOptionsHost()}${Routes.tfa_settings_user_path()}`
}

export const formatDateWithTime = (date: any): string => {
  return date.format('MM/DD/YYYY [at] h:mm A z')
}

export const formatDateWithoutTime = (date: any): string => {
  return date.format('MM/DD/YYYY')
}

interface FormatDateWithTimezoneProps {
  date: any
  timeZone: string
}

export const formatDateWithTimezone = ({
  date,
  timeZone,
}: FormatDateWithTimezoneProps): any => {
  return dayjs(date).tz(timeZone)
}

interface FormatNumberProps {
  limit?: number
  value: number
}

export const formatNumber = ({ value, limit = 10_000 }: FormatNumberProps): string => {
  let result = numeral(value).format('0,[0]')

  if (Math.abs(value) >= limit) {
    result = `${numeral(limit).format('0,[0]')}+`

    return value < 0 ? `-${numeral(limit).format('0,[0]')}+` : result
  }

  return result
}

const createRange = (length: number): (string | number)[] => {
  return Array.from(new Array(length), (_, el) => (el < 10 ? '0' + el : el))
}

interface CreateTimeDataOptionsResult {
  label: string
  value: string
}

const createRangeOptions = (
  range: (string | number)[],
): CreateTimeDataOptionsResult[] => {
  return range.map(r => ({
    value: String(r),
    label: String(r),
  }))
}

export const createTimeDataOptions = (type: string): CreateTimeDataOptionsResult[] => {
  switch (type) {
    case 'hour': {
      const range = createRange(TIME.HOURS_IN_DAY)

      return createRangeOptions(range)
    }
    case 'minute': {
      const range = createRange(TIME.MINUTES_IN_HOUR)

      return createRangeOptions(range)
    }
    default:
      return []
  }
}

export const makeDurationHumanReadable = (duration: number | null): string => {
  if (_.isNull(duration)) return 'unlimited'
  if (duration === 0) return ''

  return duration < TIME.HOURS_IN_DAY
    ? pluralize('hour', duration, true)
    : pluralize('day', Math.floor(duration / TIME.HOURS_IN_DAY), true)
}

export const isFullDays = (value: number): boolean => {
  return value >= TIME.HOURS_IN_DAY && value % TIME.HOURS_IN_DAY === 0
}

export const humanizeHoursInDays = (hours: number): string => {
  if (hours < 0) {
    return ''
  }

  if (isFullDays(hours)) {
    const days = hours / TIME.HOURS_IN_DAY

    return `${days} ${pluralize('day', days)}`
  }

  return `${hours} ${pluralize('hour', hours)}`
}

export const isConversionSpace = (url: string): boolean => {
  return /\/sites\/[^\/]+\/conversion/.test(url)
}

export const isLoyaltySpace = (url: string): boolean => {
  return /\/sites\/[^\/]+\/loyalty/.test(url)
}

export const isReferralSpace = (url: string): boolean => {
  return /\/sites\/[^\/]+$|\/sites\/[^\/]+\/(campaigns|reports|offers|offer_shares|origins|referrals|incentive_outcomes|people|static_assets|coupon_lists|personal_coupon_lists|locale_entries|settings_changes|previous_customers|event_uploads|secured_files|visitor_offers|customer_emails|split_tests|page_snapshots|customer_service_portal)+/.test(
    url,
  )
}

export const getSiteSpace = (pathname: string): AllowedSpaces => {
  if (isConversionSpace(pathname)) {
    return 'conversion'
  }
  if (isLoyaltySpace(pathname)) {
    return 'loyalty'
  }

  return 'referral'
}

export const getUrlSpaceParameterBySiteSpace = (space: AllowedSpaces): string => {
  if (!['referral', 'conversion', 'loyalty'].includes(space)) {
    throw new Error('Invalid argument')
  }

  return space === 'referral' ? '' : space
}

export const getUrlSpaceParameter = (url: string): string => {
  return getUrlSpaceParameterBySiteSpace(getSiteSpace(url))
}

interface checkSpaceCompatibilityProps {
  campaignSpace: string | null
  currentSpace: string
}

export const checkSpaceCompatibility = ({
  currentSpace,
  campaignSpace,
}: checkSpaceCompatibilityProps): boolean => {
  return currentSpace === campaignSpace
}

interface GetFormattedTimeProps {
  date: string
  defaultValue?: string
  format: string
  timeZoneIdentifier: string
}

export const getFormattedTime = ({
  date,
  timeZoneIdentifier,
  format,
  defaultValue = '',
}: GetFormattedTimeProps): string => {
  return date ? dayjs(date).tz(timeZoneIdentifier).format(format) : defaultValue
}

export const addHash = (str: string): string => {
  if (str === '') {
    return ''
  }

  return `#${str}`
}

interface ScrollToIdProps {
  offset: number
}

export const scrollToId = (
  id: string,
  { offset }: ScrollToIdProps = { offset: -24 },
): void => {
  const element = $(addHash(id))

  if (element.length) {
    scrollTo({
      position: element?.offset()?.top || 0 + offset,
    })
  }
}

export const setGAProductSpace = (space: AllowedSpaces): void => {
  window.ga?.('set', 'dimension4', space)
}

interface GetPeriodInHoursProps {
  defaultPeriod?: number
  period: 'days' | 'hours'
  value: number | any
}

export const getPeriodInHours = ({
  period,
  value,
  defaultPeriod = 0,
}: GetPeriodInHoursProps): number | string => {
  const availablePeriods = ['days', 'hours']

  if (period && !availablePeriods.includes(period)) {
    throw new Error('unexpected value for period')
  }

  if (value === '') {
    return defaultPeriod
  }

  return period === 'days' ? value * TIME.HOURS_IN_DAY : value
}

export const isValidUploadedImageType = (type: string): boolean => {
  switch (type) {
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
      return true
    default:
      return false
  }
}

export const fetchImageFile = async (iconUrl: string): Promise<File> => {
  const response = await fetch(iconUrl, {
    cache: 'no-cache',
  })

  const blob = await response.blob()
  const fileName = last(iconUrl.split('/')) || 'image'

  return new File([blob], fileName, { type: blob.type })
}

interface RoundValueProps {
  precision?: number
  value: number
}

export const roundValue = ({ value, precision = 2 }: RoundValueProps): number => {
  return round(value, precision)
}

export const noWrapLastWord = (data: string): string => {
  const words = data.split(' ') || ['']
  const last = words.length === 1 ? words.pop() : `\u00a0${words.pop()}`

  return `${words.join(' ')}${last}`
}

export const sortInAlphabeticalOrder = (a: string, b: string): number => {
  return a > b ? 1 : -1
}

export const getPlacementKey = (data: keyof typeof PLACEMENTS_ABBREVIATIONS): string => {
  return data === 'LW' ? 'second_placement_id' : 'placement_id'
}

export const getSupportEmail = (): string => {
  const supportEmail = document.querySelector('.js-constants')?.dataset.supportEmail

  return window.Constants?.SUPPORT_EMAIL || supportEmail
}

export const customerSuccessEmail = (): string => {
  const customerSuccessEmail =
    document.querySelector('.js-constants')?.dataset.customerSuccessEmail

  return window.Constants?.CUSTOMER_SUCCESS_EMAIL || customerSuccessEmail
}

export const defaultUrlOptionsHost = (): string => {
  const defaultUrlOptionsHost =
    document.querySelector('.js-constants')?.dataset.defaultUrlOptionsHost

  return window.Constants?.DEFAULT_URL_OPTIONS?.host || defaultUrlOptionsHost
}

export const publicUrlOptionsHost = (): string => {
  const publicUrlOptionsHost =
    document.querySelector('.js-constants')?.dataset.publicUrlOptionsHost

  return window.Constants?.PUBLIC_URL_OPTIONS?.host || publicUrlOptionsHost
}

export const attachmentSizeLimit = (): number => {
  return (
    window.Constants?.ATTACHMENT_SIZE_LIMIT ||
    parseInt(document.querySelector('.js-constants')?.dataset.attachmentSizeLimit)
  )
}
